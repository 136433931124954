// util for different validate definitions
import { Env } from '@app/utils';
import { FieldConfig } from '@types';
import { RegisterOptions } from 'react-hook-form';
import { isValidPassword } from './validation/password';

type Rules = Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;

const PO_BOX_REGEX =
  /(?:P(?:ost(?:al)?)?[\.\-\s]*(?:(?:O(?:ffice)?[\.\-\s]*)?B(?:ox|in|\b|\d)|o(?:ffice|\b)(?:[-\s]*\d)|code)|box[-\s\b]*\d)/i;

const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const PHONE_REGEX = /^\d{3}-\d{3}-\d{4}$/;

/**
 * Builds a set of rules for use in form controller config
 */
export const createRulesObject = ({
  required,
  name,
  type,
  format,
  subfields,
  legalName,
  min,
  max,
  before,
  after,
  equals,
  doesNotExistIn,
  allowFalseValues,
  allowNegative,
  allowPOBox,
  stepperType,
  length,
  minLength,
  maxLength,
  regex,
  validate,
}: FieldConfig): Rules => {
  // begin with universal validation
  let rules = {
    required: {
      value: required && !allowFalseValues,
      message: 'Required',
    },
    validate: {},
  };

  if (validate) {
    rules.validate = {
      ...rules.validate,
      custom: validate,
    };
  }

  // when required, allows for any value (including falsy values)
  if (required && allowFalseValues) {
    rules.validate = {
      ...rules.validate,
      required: (value) => {
        if (value === null || value === undefined) return 'Required';
      },
    };
  }

  if (equals) {
    rules.validate = {
      ...rules.validate,
      equals: (value) => {
        if (value && value !== equals) return 'Must match value';
      },
    };
  }

  if (doesNotExistIn) {
    rules.validate = {
      ...rules.validate,
      doesNotExistIn: (value) => {
        if (value && Array.isArray(doesNotExistIn.array) && doesNotExistIn.array.includes(value)) {
          return doesNotExistIn.message;
        }
      },
    };
  }

  if (length !== undefined) {
    rules.validate = {
      ...rules.validate,
      length: (value) => {
        if (value && value?.length !== length) {
          return `Must be ${length} characters`;
        }
      },
    };
  }

  if (minLength !== undefined) {
    rules['minLength'] = {
      value: minLength,
      message: `Must be at least ${minLength} characters`,
    };
  }

  if (maxLength !== undefined) {
    rules['maxLength'] = {
      value: maxLength,
      message: `Must be less than ${maxLength} characters`,
    };
  }

  if (!!regex) {
    rules.validate = {
      ...rules.validate,
      regex: (value) => {
        if (value && !regex?.value?.test(value)) {
          return regex?.message;
        }
      },
    };
  }

  if (type === 'email') {
    /**
     * Email validation regex
     * StackOverflow context: https://stackoverflow.com/a/8829363
     * HTML5 spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
     */
    rules['pattern'] = {
      value: EMAIL_REGEX,
      message: 'Must be valid email address',
    };
  }

  if (type === 'password') {
    rules.validate = {
      ...rules.validate,
      password: (value) => {
        if (!isValidPassword(value)) {
          return 'Must match password constraints';
        }
      },
    };
  }

  // then add input type specific validation
  if (type === 'amount' || type === 'number' || type === 'stepper') {
    if (!allowNegative) {
      rules['min'] = {
        value: 0,
        message: 'Amount must be positive',
      };
    }

    if (min !== undefined) {
      rules['min'] = {
        value: stepperType === 'percentage' ? min : min,
        message: `Amount must be greater than ${min}`,
      };
    }
    if (max !== undefined) {
      const amount = max?.amount || max;

      rules['max'] = {
        value: amount,
        message: max?.message || `Amount must be less than ${amount}`,
      };
    }
  }

  if (type === 'alias') {
    rules.validate = {
      ...rules.validate,
      alias: (value) => {
        if (!EMAIL_REGEX.test(value) && !PHONE_REGEX.test(value)) {
          return 'Must be valid email or phone';
        }
      },
    };
  }

  if (type === 'code') {
    rules['pattern'] = {
      value: /^\d{6}$/,
      message: 'Must be a six digit code',
    };
  }

  if (type === 'ssn') {
    rules['pattern'] = {
      value: /^(\d{3}-\d{2}-\d{4}|\*{3}-\*{2}-\d{4})$/,
      message: 'Must be a valid SSN',
    };

    rules.validate = {
      ...rules.validate,
      validSSN: (value) => {
        // allows ***-**-XXXX
        if (/^(\*{3}-\*{2}-\d{4})$/.test(value)) {
          return undefined;
        }

        // allow this ID proofing ssn on dev
        if (value === '666-50-4240' && !Env.isProd) return undefined;

        const ssnRegex =
          !/^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/.test(value) ||
          /123-45-6789|219-09-9999|078-05-1120|111-11-1111|222-22-2222|333-33-3333|444-44-4444|555-55-5555|666-66-6666|777-77-7777|888-88-8888|999-99-9999/.test(
            value,
          );

        return value && ssnRegex ? 'Must be a valid SSN' : undefined;
      },
    };
  }

  if (type === 'date') {
    if (format === 'YYYY-MM-DD') {
      const dateError = 'Must be a valid date';

      rules['pattern'] = {
        value: /^\d{4}-\d{2}-\d{2}$/,
        message: 'Must be a valid date',
      };

      rules['validate'] = (value) => {
        try {
          if (value) {
            const d = new Date(value);

            if (isNaN(d.getTime())) {
              return dateError;
            }

            if (name === 'dob') {
              // If dob, also validate that it's not in the future.
              const today = new Date();
              return d <= today || 'Must be a valid birthday';
            }

            if (before && before.date <= value) {
              if (before.inclusive && value === before.date) {
                return undefined;
              } else {
                return before.message;
              }
            }

            if (after && value <= after.date) {
              if (after.inclusive && value === after.date) {
                return undefined;
              } else {
                return after.message;
              }
            }
          }

          return undefined;
        } catch {}

        return dateError;
      };
    }
  }

  if (type === 'phone') {
    if (subfields) {
      rules.validate = {
        ...rules.validate,
        number: (value) => {
          if (required && !/^\d{3}-\d{3}-\d{4}$/.test(value?.number)) {
            return 'Must be a ten digit phone number';
          }
        },
        type: (value) => {
          if (required && !value?.type) return 'Needs phone type';
        },
      };
    } else {
      rules['pattern'] = {
        value: /^\d{3}-\d{3}-\d{4}$/,
        message: 'Must be a ten digit phone number',
      };
    }
  }

  if (type === 'address') {
    // address needs to be filled out if any of the child fields have been partially filled OR if field is required
    const needsAddress = (address) =>
      required ||
      address?.street1 ||
      address?.street2 ||
      address?.city ||
      address?.state ||
      address?.zip;

    rules.validate = {
      ...rules.validate,
      street1: (address) => {
        if (needsAddress(address) && !address?.street1) return 'Needs street address';

        if (address?.street1 && PO_BOX_REGEX.test(address?.street1) && !allowPOBox) {
          return 'P.O. boxes are invalid';
        }
      },
      street2: (address) => {
        if (address?.street2 && PO_BOX_REGEX.test(address?.street2) && !allowPOBox) {
          return 'P.O. boxes are invalid';
        }
      },
      city: (address) => {
        if (needsAddress(address) && !address?.city) return 'Missing city';
      },
      state: (address) => {
        if (needsAddress(address) && !address?.state) return 'Missing state';
      },
      zip: (address) => {
        if (address?.zip && address?.zip?.length !== 5) return 'Must be a 5 digit zip';
        if (needsAddress(address) && !address?.zip) return 'Missing zip';

        // @todo check for valid zip
      },
    };
  }

  if (type === 'zip') {
    rules.validate = {
      ...rules.validate,
      zip: (value) => {
        if (value?.zip && value?.zip?.length !== 5) return 'Must be a 5 digit zip';
        if (required && !value?.zip) return 'Missing zip';
      },
      county: (value) => {
        if (value?.zip && !value?.fips) return 'Must select a county';
      },
    };
  }

  if (type === 'signature') {
    rules.validate = {
      ...rules.validate,

      chars: (val) => {
        // starts with letter, allows apostrophe, underscore, periods, dashes
        if (!!val && !/^[A-Za-z0-9 '.-]+$/.test(val)) {
          return 'Invalid special characters';
        }
      },

      matchesSignature: (val) => {
        const normalizedFormValue = val?.replace(/[^a-zA-Z]/g, '')?.toLowerCase();
        const normalizedLegalName = legalName?.replace(/[^a-zA-Z]/g, '')?.toLowerCase();

        if (!!val && normalizedFormValue !== normalizedLegalName) {
          return 'Sign your name as it appears below';
        }
      },
    };
  }

  if (type === 'legalName') {
    rules.validate = {
      ...rules.validate,
      givenName: (vals) => {
        if (subfields.givenName && !vals?.givenName) return 'Needs first name';

        if (vals?.givenName && !/^[A-Za-z0-9 '.-]+$/.test(vals?.givenName)) {
          return 'Invalid special characters';
        }

        if (vals?.givenName && !/^[a-zA-Z]/.test(vals?.givenName)) {
          return 'Must start with letter';
        }

        if (vals?.givenName && vals?.givenName?.length > 25) {
          return 'Limited to 25 characters';
        }
      },
      middleName: (vals) => {
        if (vals?.middleName && !/^[A-Za-z0-9 '.-]+$/.test(vals?.middleName)) {
          return 'Invalid special characters';
        }

        if (vals?.middleName && !/^[a-zA-Z]/.test(vals?.middleName)) {
          return 'Must start with letter';
        }
      },
      familyName: (vals) => {
        if (subfields.familyName && !vals?.familyName) return 'Needs last name';

        if (vals?.givenName && !/^[A-Za-z0-9 '.-]+$/.test(vals?.givenName)) {
          return 'Invalid special characters';
        }

        if (vals?.familyName && !/^[a-zA-Z]/.test(vals?.familyName)) {
          return 'Must start with letter';
        }

        if (vals?.familyName && vals?.familyName?.length > 40) {
          return 'Limited to 40 characters';
        }
      },
    };
  }

  return rules;
};
